import React, { useState } from 'react'
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import bread1 from '../assets/img/bread1.png';
import bread2 from '../assets/img/bread2.png';
import bread3 from '../assets/img/bread3.png';
import free1 from '../assets/img/free1.jpg';
import free2 from '../assets/img/free2.jpg';
import free3 from '../assets/img/free3.jpg';
import info1 from '../assets/img/info1.png';
import info2 from '../assets/img/info2.png';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import info3 from '../assets/img/info3.png';
import info4 from '../assets/img/info4.png';
import payment from '../assets/img/payment.jpg';
import footer from '../assets/img/footer.png';
import msDetail1 from '../assets/img/msDetail1.png';
import msDetail2 from '../assets/img/msDetail2.png';
import msDetail3 from '../assets/img/msDetail3.png';
import msDetail4 from '../assets/img/msDetail4.png';
import testimonalImage from '../assets/img/testimonial-image.jpg';
import promoBanner from '../assets/img/promo-banner.png'
import chef1 from '../assets/img/Chef-Suriani.png'
import chef2 from '../assets/img/Chef-Raudhah.jpg'
import comma from '../assets/img/inverted-commas.png';
import divider from '../assets/img/Divider.png'
import ReactPlayer from 'react-player'
import TermsNConditions from '../pages/TermsNConditions';
import AboutUs from '../pages/AboutUs';
import PayPalBtn from '../components/PayPalBtn'
import {
  Grow, Dialog, List, ListItem,
  ListItemText, Divider, Hidden, CardMedia, CardContent,
  makeStyles, CardActionArea, Card, Box,
  Fade, Zoom, Slide, Paper, Button, IconButton, Collapse, Grid, Typography, Toolbar, AppBar
} from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import app from '../api/base.js'
import { getAuth, signOut, sendPasswordResetEmail  } from "firebase/auth";
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
  FiCardMedia
} from "../components/FullImageCard";

export const freeClass = {
  array: ['New videos published monthly', 'New Recipes to Discover', 'All levels of experience'],
  items: [bread1, bread2, bread3],
  free: [free1, free2, free3],
  cakes: ['Bombolini', 'Cheese Cake', 'Churros'],
  info: ["An Italian filled doughnut eaten as a snack food and dessert", "A sweet dessert consisting mixed layers of soft, fresh cheese, eggs, & sugar    ", "A type of fried dough from Spanish and Portuguese cuisine"],
};

const paymentDetails = [
  // { mode: "FREE", price: '0', info: 'Free Plan\nLimited Access\nView 2 free videos on us\nDownload & Print PDF recipes\nInstant playback' },
{ mode: "PREMIUM", price: '39.90', info: 'Monthly Plan\nUnlimited Access\nView entire video library\nDownload & Print PDF recipes\nInstant playback\nLifetime Bakers Support\nNew videos every month\nMinimum Signup 12 months' },
{ mode: "ANNUAL", price: '450', info: 'Annual Plan\nUnlimited Access\nView entire video library\nDownload & Print PDF recipes\nInstant playback\nLifetime Bakers Support\nNew videos every month\nBest value for money' },
{ mode: "LIMITED TIME OFFER", price: '450', info: 'Two Year Plan\nUnlimited Access\nView entire video library\nDownload & Print PDF recipes\nInstant playback\nLifetime Bakers Support\nNew videos every month\nBest value for money' },
]

export const homePage = [{ label: 'All New Recipes\nMonthly', media: info1 },
{ label: 'Monthly Booking\nConsultation', media: info2 },
{ label: 'Learn Anytime,\nAnywhere', media: info3 },
{ label: 'Lifetime Bakers\nSupport', media: info4 }]

export const msDetailRows = [{ label: 'New Recipes Monthly', media: msDetail1 },
{ label: 'Baking Consultations Monthly', media: msDetail2 },
{ label: 'Learn anytime, anywhere, at your own pace.', media: msDetail3 },
{
  label: 'Lifetime Bakers Support – Our staff will be ready to help you with any baking recipes and techniques, issues you have.',
  media: msDetail4
}]

export const forms = [{ label: "Name", type: 'text', name: 'name' },
{ label: "Email", type: 'email', name: 'email' },
{ label: "Subject", type: 'text', name: 'subject' },
{ label: "Message", type: 'text', name: 'message' }];


export const ResponsivePlayer = ({ link, play }) => {
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        playing={play}
        url={link}
        width='100%'
        height={350}
        controls={true}
      />
    </div>
  )
}

export function fonter(text, variant, type) {
  const fontWeight = type ? `fontWeightBold` : `fontWeightLight`
  return (
    
    <Typography inline variantMapping={{ h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6', subtitle1: 'h6', subtitle2: 'h6', body1: 'span', body2: 'span', }} variant={variant}>
      <Box component="div" display="inline" fontFamily="Poppins" fontWeight={fontWeight}  >
        {text}
      </Box>
    </Typography>
  )
}

export const PageDivider = ({ text }) =>
  <Box textAlign='center' my={5}>
    <img alt="logo" src={divider} height="50" />
    <br />
    <div style={{ color: '#542224' }}>{fonter(text, `h5`, true)}</div>
  </Box>

//success and info alert message
export const alertbar = (r, state) => {
  return (
    <Collapse id="alert" in={state}>
      <Alert style={{ marginBottom: 10, marginTop: 10, borderRadius: 20 }} variant="filled" severity={r.severity} action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small">
        </IconButton>}
      > {r.message}
      </Alert>
    </Collapse>
  )
}

export const InfoRow = ({ checked, value }) =>

  <Box mx={!value && { xs: 3, sm: 3, md: 10, lg: 20, xl: 40 }}>
    <Grid style={{ marginTop: 10 }} container spacing={2}>
      {[...(new Array(3))].map((_, i) => {
        return (
          <Grid key={i} item xs={12} sm={value ? value : 4}>
            <Fade in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1000 + (i * 1500) } : {})}>
              <FiCard style={{ borderRadius: 20 }}>
                <FiCardActionArea>
                  <FiCardMedia
                    media="picture"
                    image={freeClass.items[i]}
                  />
                  <FiCardContent style={{
                    color: "#ffffff",
                    backgroundColor: "rgba(0,0,0,.24)",
                    textAlign: 'center',
                    alignContent: 'center'
                  }}>
                    {fonter(freeClass.array[i], `h5`, false)}
                  </FiCardContent>
                </FiCardActionArea>
              </FiCard>
            </Fade>
          </Grid>
        )
      })}
      <Grid item xs={12} style={{textAlign: "center"}}>
      <Fade in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1000 + (4 * 1500) } : {})}>
              <Button
              href="http://eepurl.com/hK4X8H"
              style={{ padding: '10px', borderRadius: 20, backgroundColor: '#542234', color: 'white' }}>
              <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                  Subscribe to Our Newsletter
                </Box>
              </Button>
              </Fade>
    </Grid>
    </Grid>


  </Box>

export const Promo = ({ role, checked, executeScroll, handleClickOpen }) =>
  <Fade in={checked}
    style={{ transformOrigin: '0 0 0' }}
    {...(checked ? { timeout: 500 } : {})}>
    <Card square style={{
      backgroundImage: 'url(' + promoBanner + ')',
      backgroundSize: "cover",
      color: "#542224"
    }}>
      <Box p={2} mx={{ xs: 0, sm: 2, md: 10, lg: 30, xl: 50 }}>
        <Grow in={checked} style={{ transformOrigin: 'center bottom' }}
          {...(checked ? { timeout: { enter: 1000, exit: 3000 } } : {})}>
          {/* {{ xs:0, sm: 0, md:40, lg: 40  }} */}
          <Grid align="center" justifyContent="center" alignItems="center" style={{ padding: '10px' }} container>
            <Grid item xs>
              {fonter(`Join Asia’s # 1 Online Baking Community Now`, `h5`, true)}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ width: '100%' }}>
                <Box display="flex">
                  <Box width="100%">
                    <Button
                      onClick={executeScroll}
                      style={{ background: '#542224', borderRadius: 20, color: 'white', whiteSpace: 'nowrap', marginRight: 10 }}
                      variant="contained">
                      Join Now</Button>
                  </Box>
                  <Box flexShrink={0}>
                    {(() => {
                      switch (role) {
                        case 'premium'&&'admin':
                          return (
                            <Button
                              component={Link} to={"/Home/premiumClasses"}
                              style={{ color: '#542224', borderRadius: 20, border: '1px solid #542224', whiteSpace: 'nowrap' }}
                              variant="outlined">
                              Our Online Classes</Button>
                          )
                        case 'free':
                          return (
                            <Button
                              component={Link} to={"/Home/freeClasses"}
                              style={{ color: '#542224', borderRadius: 20, border: '1px solid #542224', whiteSpace: 'nowrap' }}
                              variant="outlined">
                              Our Online Classes</Button>
                          )
                        default:
                          return (
                            <Button
                              onClick={handleClickOpen}
                              style={{ color: '#542224', borderRadius: 20, border: '1px solid #542224', whiteSpace: 'nowrap' }}
                              variant="outlined">
                              Our Online Classes</Button>
                          )
                      }

                    })()}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grow>
      </Box>
    </Card>
  </Fade>

export const Chefs = ({ classes }) =>
  <Box mx={3}>
    <PageDivider text={`Our Chefs`} />
    <Grid container justifyContent="center" spacing={3}>
      <Grid item>
        <Card elevation={8} className={classes.chefs}>
          <CardMedia
            className={classes.media}
            image={chef1}
          />
          <CardContent style={{ padding: 30 }}>
            {fonter(`Chef Suriani`, `h5`, true)}
            {fonter(`Founder`, `body1`, false)}
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card elevation={8} className={classes.chefs}>
          <CardMedia
            className={classes.media}
            image={chef2}
          />
          <CardContent style={{ padding: 30 }}>
            {fonter(`Chef Raudhah`, `h5`, true)}
            {fonter(`SistersCookies Executive Chef`, `body1`, false)}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Box>


export const Testimonials = ({ animeRef, animeState, classes, testimonialDetails }) =>
  <Fade in={animeState}
    style={{ transformOrigin: '0 0 0' }}
    {...(animeState ? { timeout: 5000 } : {})}>
    <Paper ref={animeRef} elevation={0} style={{ paddingBottom: 100 }} justify="center">
      <Grid container justifyContent="center" style={{ padding: '10px' }}>
        <PageDivider text={`What Our Students Say`} />
      </Grid>
      <Box mx={{ xs: 0, sm: 2, md: 10, lg: 20, xl: 50 }}>

        <Card elevation={0} className={classes.root} >
          <Grid container direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={12} sm={4}>
              <Slide direction="right" in={animeState} {...(animeState ? { timeout: 4000 } : {})} mountOnEnter unmountOnExit>
                <CardMedia
                  component="img"
                  image={testimonalImage}
                />
              </Slide>
            </Grid>
            <Grid item xs={12} sm={8} >

              <CardContent  >
                <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true}>
                  {[...(new Array(testimonialDetails.length))].map((_, i) => {
                    return (
                      <div key={testimonialDetails[i].header}>
                        <Card elevation={0}>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={1}>
                                <img src={comma} />
                              </Grid>
                              <Grid item xs />
                            </Grid>
                            <Box>
                              {fonter(testimonialDetails[i].header, `h4`, true)}
                              {fonter(testimonialDetails[i].detail, `body1`, true)}
                              <div style={{ color: '#542224' }}>{fonter(testimonialDetails[i].author, `body1`, true)}</div>
                            </Box>
                          </CardContent>
                        </Card>
                      </div>
                    )
                  })}
                </Carousel>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Paper>
  </Fade>

export const PaymentOptions = ({ animeState, handleClickOpen, animeRef, paypalKey, paymentModes }) => {
  const [openPayment, setOpenPayment] = useState({ state: false, dialog: 0 });
  const [openPaypal, setOpenPaypal] = useState(false);

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      'plan_id': paymentModes[openPayment.dialog].value
    });
  };

  const paypalOnError = (err) => {
    alert(`Please take a minute to make another subscription if cancelled`)
    window.location.reload();
  }
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    const auth = getAuth();
    // console.log(data.subscriptionID);

    if (data.subscriptionID != ''){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: auth.currentUser.email,
          subscriptionID: data.subscriptionID,
          orderID: data.orderID,
          plan: paymentModes[openPayment.dialog].option,
          months: paymentModes[openPayment.dialog].months
        })
      };


      fetch('/api/setSubscription', requestOptions)
      .then(response => response.json())
      .then(data => alert(data.item))
      .then(signOut(auth));
    }
  };

  //3 is for limited time offer
  const arrayLength = [0, 1]
  const auth = getAuth();

  return (
    <Box mx={{ xs: 3, sm: 3, md: 10, lg: 20, xl: 40 }}>
      <Grid
        ref={animeRef}
        container
        id='payments'
        justifyContent="center"
        direction="column"
        style={{ minHeight: "100vh", paddingBottom: 30 }}
      >
        <Dialog open={openPayment.state} onClose={() => {
          setOpenPayment(
            { ...openPayment, state: false })
          setOpenPaypal(false)
        }}>

          <Collapse in={!openPaypal}>
            <Toolbar>
              {fonter(`Choose the plan that best suits you`, `h6`, false)}
            </Toolbar>
          </Collapse>
          <Box pb={2} px={2} textAlign='center'>
            <List>
              <ListItem button>
                <ListItemText
                  primary={fonter(paymentModes[openPayment.dialog].option, `h5`, true)}
                  secondary={fonter(`SGD${paymentModes[openPayment.dialog].price}`, `body1`, true)} />
              </ListItem>
            </List>


            <Collapse in={openPaypal}>
              <PayPalBtn
                paypalKey={paypalKey}
                createSubscription={paypalSubscribe}
                onApprove={paypalOnApprove}
                catchError={paypalOnError}
                onError={paypalOnError}
                onCancel={paypalOnError}
              />
            </Collapse>

            <Collapse in={!openPaypal}>
              <Button
                fullWidth 
                onClick={() => setOpenPaypal(true)}
                style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px' }}>
                {fonter(`Subscribe`, `body1`, false)}
              </Button>
            </Collapse>
          </Box>
        </Dialog>


        <Grid item>
          <Fade in={animeState}
            style={{ transformOrigin: '0 0 0' }}
            {...(animeState ? { timeout: 2000 } : {})}>
            <Box textAlign='center' my={5}>
              <img alt="logo" src={divider} height="50" />
              <br />
              <div style={{ color: '#542224' }}>{fonter(`Our Plans`, `h5`, true)}</div>
            </Box>
          </Fade>
        </Grid>
        <Grid item>
          <Grid container>
            {arrayLength.map((_, i) => {
              const detailArray = paymentDetails[i].info.split('\n')
              return (
                <Grid key={paymentDetails[i].mode} item xs={12} sm={(auth.currentUser) ? 4 : 4} >
                  <Zoom in={animeState} style={{ marginTop: i == 0 && 43 }} {...(animeState ? { timeout: 2000 + i * 1000 } : {})}>
                    <Card square elevation={10} style={{ margin: 15, borderRadius: 20, backgroundColor: (i == 3 ? '#e7f5f6' : '#fdf4ed'), textAlign: 'center' }}>
                      <CardActionArea >
                        {(() => {
                          switch (i) {
                            case 1:
                              return (
                                <Box style={{ color: 'white', backgroundColor: '#d26e4c', padding: '10px' }}>
                                  {fonter(`SAVE $28!!`, `body1`, true)}</Box>
                              )
                            case 2:
                              return (
                                <Box style={{ backgroundColor: '#50b848', padding: '10px', color: 'white', textAlign: 'center' }}>
                                  {fonter(`****MOST POPULAR****`, `body1`, true)}</Box>
                              )
                            case 3:
                              return (
                                <Box style={{ color: 'white', backgroundColor: '#15a1ad', padding: '10px' }}>
                                  {fonter(`SAVE $507.60!!`, `body1`, true)}</Box>
                              )
                          }
                        })()}



                        <Box pt={i === 0 ? 6.5 : 1} pb={1} style={{ backgroundColor: i == 3 ? '#15a1ad':'#ed9474', color: 'white', textAlign: 'center' }}>
                          {fonter(paymentDetails[i].mode, `h6`, true)}</Box>

                        <CardContent style={{ textAlign: 'center' }}>
                          <Toolbar p={10}>
                            {fonter(`$`, `h5`, false)}
                            {fonter(paymentDetails[i].price, `h4`, true)}
                            {i == 0 && fonter(`/mo`, `h5`, false)}
                            {i == 1 && fonter(`/year`, `h5`, false)}
                            {i == 2 && fonter(`/2 years`, `h5`, false)}
                          </Toolbar>
                          <List>
                            {detailArray.map((item, i) =>
                              <Card key={`detail` + i} style={{
                                borderTopLeftRadius: i == 0 && 20,
                                borderTopRightRadius: i == 0 && 20,
                                borderBottomLeftRadius: i == detailArray.length - 1 && 20,
                                borderBottomRightRadius: i == detailArray.length - 1 && 20,
                              }} elevation={4}>
                                <ListItem>
                                  <ListItemText primary={fonter(item, `subtitle1`, true)} />
                                  <CheckCircleOutlineIcon />
                                </ListItem>
                              </Card>)}
                          </List>
                        </CardContent>
                      </CardActionArea>


                      <Button
                        onClick={() => {
                          (auth.currentUser) ?
                            setOpenPayment({ state: true, dialog: i }) :
                            handleClickOpen()
                        }}
                        style={{ padding: '10px', borderRadius: 20, backgroundColor: '#f6ded2', marginBottom: '10px', color: '#542224' }}>
                        <Box fontFamily="Poppins" fontWeight="fontWeightBold" mx={1}>
                          {(auth.currentUser) ? `Get Started` : `Sign Up Today!`}
                        </Box>
                      </Button>
                    </Card>
                  </Zoom>
                </Grid>)
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>)

}



export const Header = ({ banner, checked, title, sub }) =>
  <Fade in={checked}
    style={{ transformOrigin: '0 0 0' }}
    {...(checked ? { timeout: 500 } : {})}>
    <Card square style={{
      backgroundImage: 'url(' + banner + ')',
      backgroundSize: "cover",
      color: "#f5f5f5"
    }}>
      <Grow in={checked} style={{ transformOrigin: 'center bottom' }}
        {...(checked ? { timeout: { enter: 1000, exit: 3000 } } : {})}>
        <CardContent>
          <Box mt={30} mb={20} textAlign='center'>
            {/* Mobile UI */}
            <Hidden xlUp lgUp>
              {fonter(<h3 style={{ textShadow: '5px 5px 5px  #2A1F17' }}>{title}</h3>, `h3`, true)}
            </Hidden>
            {/* Desktop UI */}
            <Hidden mdDown smDown xsDown>
              {fonter(<h2 style={{ textShadow: '5px 10px 5px  #2A1F17' }}>{title}</h2>, `h2`, true)}
            </Hidden>

            {fonter(<h4 style={{ textShadow: '5px 5px 5px  #2A1F17' }}>{sub}</h4>, `h4`, true)}
          </Box>
        </CardContent>
      </Grow>
    </Card>
  </Fade>

export const Footer = ({ loggedIn, loginButton, registerButton, contactUsButton, logoutButton }) => {

  const [openAboutUs, setOpenAboutUs] = useState(false);
  const [openTNC, setOpenTNC] = useState(false);
  const auth = getAuth();

  const resetPassword = () => {
    sendPasswordResetEmail(auth, auth.currentUser.email).then(() => {
      alert('An email to reset your password has been sent, please check your SPAM folder')
    }).catch(function (error) {
      alert(error)
    });

  }

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 20,
      flexGrow: 1,
      backgroundImage: `url(${footer})`,
      color: 'white'
    },
    button: {
      textTransform: 'none',
      justifyContent: "flex-start",
      color: 'white'
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      position: 'relative',
      backgroundImage: `url(${footer})`,
      justifyContent: 'space-between',
      color: 'white'
    },

  }))

  const classes = useStyles();
  return (
    <Box justifyContent="center" className={classes.root} >
      <TermsNConditions open={openTNC} handleClose={() => setOpenTNC(false)} />
      <AboutUs open={openAboutUs} handleClose={() => setOpenAboutUs(false)} />
      <Grid container justifyContent="center" spacing={4}>
        <Grid key={0} item xs={6} sm={6} lg={2}>
          <Box>
            {fonter(`SisterCookies`, `h5`, true)}
            <br />
            {fonter(`Join our online Baking Community and start making money from what you learn in less than 10 days`, `body1`, false)}
          </Box>
        </Grid>
        <Grid key={1} item xs={6} sm={6} lg={2}>
          {fonter(` Quick Links`, `h6`, true)}
          <List>
            <ListItem button onClick={() => setOpenAboutUs(true)}>
              <ListItemText primary='About Us' />
            </ListItem>

            {loggedIn ?
              <div>
                <ListItem button component={Link} to={"/Home/freeClasses"} >
                  <ListItemText primary='Online Classes' />
                </ListItem>
                <ListItem button component={Link} to={"/Home/contactUs"}>
                  <ListItemText primary='Contact Us' />
                </ListItem>
              </div>
              :
              <div>
                <ListItem button onClick={loginButton}>
                  <ListItemText primary='Online Classes' />
                </ListItem>
                <ListItem button onClick={contactUsButton}>
                  <ListItemText primary='Contact Us' />
                </ListItem>
              </div>
            }
            <ListItem button onClick={() => setOpenTNC(true)}>
              <ListItemText primary='Terms & Conditions' />
            </ListItem>
          </List>
        </Grid>
        <Grid key={2} item xs={6} sm={6} lg={2}>
          {fonter(` Useful Links`, `h6`, true)}
          <List>
            {!loggedIn &&
              <Box>
                <ListItem button onClick={loginButton}>
                  <ListItemText primary='Login' />
                </ListItem>
                <ListItem button onClick={registerButton}>
                  <ListItemText primary='Register' />
                </ListItem>
              </Box>
            }

            {loggedIn &&
              <Box>
                <ListItem button onClick={logoutButton}>
                  <ListItemText primary='Logout' />
                </ListItem>
                <ListItem button onClick={resetPassword}>
                  <ListItemText primary='Password Reset' />
                </ListItem>
                <ListItem button>
                  <ListItemText primary='My Account' />
                </ListItem>
              </Box>
            }


          </List>
        </Grid>
        <Grid key={3} item xs={6} sm={6} lg={2}>
          {fonter(`Contact`, `h6`, true)}
          <List>
            <ListItem button component='a' href="tel:+65 9711 1350">
              <ListItemText primary='Phone' />
            </ListItem>
            <ListItem button component='a' href="mailto:sisterscookies@yahoo.com.sg">
              <ListItemText primary='Email' />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Divider style={{ background: 'white' }} />
      <br></br>

      <Toolbar>
        <Box edge='start'>
          {fonter(` Copyrights © 2020 SisterCookies All Rights Reserved`, `body1`, false)}
        </Box>

        <Box style={{ flexGrow: 1 }} />
        <Box edge='end'>
          {fonter(`Payment Methods:  `, `body1`, false)}
          <img src={payment} width={100} />
        </Box>
      </Toolbar>
    </Box>
  );
};